<template>
  <div class="page-lottery-mine">
    <div class="page-title">我的奖品</div>
    <van-tabs v-model:active="tabActive" type="card">
      <van-tab title="未兑奖">
        <div class="rewards">
          <div v-for="(item, index) in list" :key="index" class="rewards-item">
            <div class="rewards-item__cont">
              <div class="rewards-item__name">奖品：{{ item.prizeName }}</div>
              <div class="rewards-item__act">活动：{{ item.luckyDrawName }}</div>
              <div class="rewards-item__time">中奖时间：{{ item.createTime }}</div>
            </div>
            <div class="rewards-item__opts">
              <div class="btn btn-primary" @click="viewAct(item)">查看活动</div>
              <div class="btn btn-default" @click="handleExchange(item)">立即兑换</div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="已兑奖">
        <div class="rewards">
          <div v-for="(item, index) in list2" :key="index" class="rewards-item">
            <div class="rewards-item__cont">
              <div class="rewards-item__name">奖品：{{ item.prizeName }}</div>
              <div class="rewards-item__act">活动：{{ item.luckyDrawName }}</div>
              <div class="rewards-item__time">中奖时间：{{ item.createTime }}</div>
              <div class="rewards-item__time">领奖时间：{{ item.exchangeTime }}</div>
            </div>
            <div class="rewards-item__opts">
              <div class="btn btn-primary" @click="viewAct(item)">查看活动</div>
              <div v-if="item.exchangeStatus == 2" class="btn btn-default" @click="viewShip(item)"
                >查看物流</div
              >
              <div v-if="item.exchangeStatus == 1 && item.prizeType != 3" class="btn btn-disabled"
                >已兑换</div
              >
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <van-popup v-model:show="shipVisible">
      <div class="common-popup ship-popup">
        <div class="popup-title">
          <span>查看物流</span>
        </div>
        <div class="ship-info">
          <div class="ship-info-item">物流公司：{{ shipName || '暂无物流信息' }}</div>
          <div class="ship-info-item"
            >物流单号：{{ shipSn || '暂无物流信息' }}
            <span v-if="shipSn" class="copy" @click="handleCopy">复制</span>
          </div>
        </div>
      </div>
    </van-popup>

    <Exchange
      :id="luckyDrawWinningRecordLogId"
      ref="exchangeRef"
      :name="prizeName"
      @done="getList"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Tab, Tabs, Popup, Toast } from 'vant'
import { copyToClipboard } from '@/utils/index'
import { getMyRewards } from '@/api/lottery'
import Exchange from './components/exchange.vue'

export default defineComponent({
  name: 'LotteryMine',

  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    Exchange,
  },

  setup() {
    const { push } = useRouter()
    const tabActive = ref(0)
    const list = ref<Recordable[]>()
    const list2 = ref<Recordable[]>()
    const shipVisible = ref(false)
    const shipName = ref('')
    const shipSn = ref('')
    const exchangeRef = ref()
    const luckyDrawWinningRecordLogId = ref('')
    const prizeName = ref('')

    const getList = () => {
      fetchRewards(0).then((data) => {
        list.value = data
      })
      fetchRewards(1).then((data) => {
        list2.value = data
      })
    }

    const fetchRewards = (exchangeStatus: number): Promise<Recordable[]> => {
      return getMyRewards({ current: 1, pageSize: 1000, exchangeStatus }).then((res) => {
        const data = res.data.data.myLogPage.list as Recordable[]
        return data
      })
    }

    const viewAct = (item: Recordable) => {
      push(`/lottery?code=${item.luckyDrawCode}`)
    }

    const viewShip = (item: Recordable) => {
      shipName.value = item.logisticCompany
      shipSn.value = item.logisticSn
      shipVisible.value = true
    }

    const handleCopy = () => {
      copyToClipboard(shipSn.value).then(() => {
        Toast('复制成功')
      })
    }

    const handleExchange = (item: Recordable) => {
      luckyDrawWinningRecordLogId.value = item.luckyDrawWinningRecordLogId
      prizeName.value = item.prizeName
      exchangeRef.value.visible = true
    }

    onMounted(() => {
      getList()
    })

    return {
      tabActive,
      list,
      list2,
      shipVisible,
      shipName,
      shipSn,
      exchangeRef,
      luckyDrawWinningRecordLogId,
      prizeName,
      getList,
      viewAct,
      viewShip,
      handleCopy,
      handleExchange,
    }
  },
})
</script>

<style lang="less" scoped>
.page-lottery-mine {
  min-height: 100vh;
  background: #f9f9f9;
  .page-title {
    padding: 30px;
    font-size: 34px;
    text-align: center;
    color: #262626;
  }
  .rewards {
    width: 690px;
    margin: 60px auto;
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 194px;
      padding: 32px;
      // padding: 20px;
      margin-bottom: 20px;
      background: #fff;
      &__cont {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: flex-start;
        font-size: 24px;
        color: #666;
      }
      &__opts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }
      &__name {
        color: #f34141;
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 172px;
        height: 54px;
        border-radius: 6px;
        font-size: 24px;
        &-primary {
          background: #f34141;
          color: #fff;
        }
        &-default {
          border: 1px solid #f34141;
          color: #f34141;
        }
        &-disabled {
          background: #b0b0b0;
          color: #fff;
        }
      }
    }
  }

  .ship-popup {
    .ship-info {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        font-size: 28px;
        .copy {
          color: #f34141;
        }
      }
    }
  }
}

.popup-title {
  padding-bottom: 30px;
  text-align: center;
  span {
    position: relative;
    display: inline-block;
    padding: 0 62px;
    font-size: 30px;
    color: #333;
    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      width: 40px;
      height: 1px;
      background: #979797;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

.common-popup {
  width: 580px;
  padding: 30px;
  border-radius: 8px;
  background: #fff;
  text-align: center;
}
</style>

<style lang="less">
.page-lottery-mine {
  // 去除popup自带的背景色
  .van-popup {
    background: transparent;
  }
}
</style>
