
import { defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Tab, Tabs, Popup, Toast } from 'vant'
import { copyToClipboard } from '@/utils/index'
import { getMyRewards } from '@/api/lottery'
import Exchange from './components/exchange.vue'

export default defineComponent({
  name: 'LotteryMine',

  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    Exchange,
  },

  setup() {
    const { push } = useRouter()
    const tabActive = ref(0)
    const list = ref<Recordable[]>()
    const list2 = ref<Recordable[]>()
    const shipVisible = ref(false)
    const shipName = ref('')
    const shipSn = ref('')
    const exchangeRef = ref()
    const luckyDrawWinningRecordLogId = ref('')
    const prizeName = ref('')

    const getList = () => {
      fetchRewards(0).then((data) => {
        list.value = data
      })
      fetchRewards(1).then((data) => {
        list2.value = data
      })
    }

    const fetchRewards = (exchangeStatus: number): Promise<Recordable[]> => {
      return getMyRewards({ current: 1, pageSize: 1000, exchangeStatus }).then((res) => {
        const data = res.data.data.myLogPage.list as Recordable[]
        return data
      })
    }

    const viewAct = (item: Recordable) => {
      push(`/lottery?code=${item.luckyDrawCode}`)
    }

    const viewShip = (item: Recordable) => {
      shipName.value = item.logisticCompany
      shipSn.value = item.logisticSn
      shipVisible.value = true
    }

    const handleCopy = () => {
      copyToClipboard(shipSn.value).then(() => {
        Toast('复制成功')
      })
    }

    const handleExchange = (item: Recordable) => {
      luckyDrawWinningRecordLogId.value = item.luckyDrawWinningRecordLogId
      prizeName.value = item.prizeName
      exchangeRef.value.visible = true
    }

    onMounted(() => {
      getList()
    })

    return {
      tabActive,
      list,
      list2,
      shipVisible,
      shipName,
      shipSn,
      exchangeRef,
      luckyDrawWinningRecordLogId,
      prizeName,
      getList,
      viewAct,
      viewShip,
      handleCopy,
      handleExchange,
    }
  },
})
